var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"flex flex-col-reverse md:flex-row justify-center mx-auto max-w-3xl pt-12 w-full",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"flex flex-col w-full items-center mb-6 mr-4"},[(_vm.data && _vm.data.order.voucher)?_c('VoucherCard',{key:("vform-" + _vm.formIndex),attrs:{"voucher":_vm.form,"order":_vm.data.order,"userVoucher":_vm.userVoucher,"isFlippable":false,"isBought":true,"isSpecialVoucher":_vm.data.data_json.isSpecialVoucher}}):_vm._e()],1),_c('div',{staticClass:"w-full flex flex-col mb-10",staticStyle:{"max-width":"368px"}},[_c('div',{staticClass:"bg-white flex flex-row flex-wrap w-full p-5 "},[_c('div',{staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"text-sm mb-2"},[_vm._v("Hintergrundbild wählen")]),_c('slider',{ref:"slider",attrs:{"options":_vm.options}},[_c('slideritem',[_c('label',{staticClass:"border file flex h-full input-field items-center justify-center px-3 py-2 rounded-sm text-xs w-full cursor-pointer flex-col",staticStyle:{"background-color":"#F7F7F7"}},[_c('i',{staticClass:"fa fa-cloud-upload-alt mb-1 text-2xl"}),_c('span',[_vm._v("Eigenes Bild")]),_c('input',{attrs:{"type":"file","id":"file","accept":"image/*","aria-label":"File browser"},on:{"change":function (e) { return _vm.croppie(e, 'croppieRef'); }}}),_c('span',{staticClass:"file-custom"})])]),_c('slideritem',[_c('div',{staticClass:"w-full h-full",staticStyle:{"background-size":"cover"},style:({
                  backgroundImage:
                    'url(' +
                    _vm.onSetCustomImage('set', _vm.form.background_image) +
                    ')',
                  backgroundColor: 'white',
                })}),_c('span',{staticClass:"absolute bg-white bottom-0 flex h-6 items-center justify-center mb-2 mr-2 right-0 rounded-full text-peach text-xs w-6 cursor-pointer",on:{"click":function($event){return _vm.onSetDefault()}}},[(_vm.selected_template == 'default')?_c('i',{staticClass:"fa fa-check"}):_vm._e()])]),_vm._l((_vm.TEMPLATES),function(tem,index){return _c('slideritem',{key:index},[_c('div',{staticClass:"w-full h-full",staticStyle:{"background-size":"cover"},style:({
                  backgroundImage:
                    'url(' + _vm.api_base_url + '/storage/' + tem.image + ')',
                  backgroundColor: 'white',
                })}),_c('span',{staticClass:"absolute bg-white bottom-0 flex h-6 items-center justify-center mb-2 mr-2 right-0 rounded-full text-peach text-xs w-6 cursor-pointer",on:{"click":function($event){return _vm.onSelectTemplate(
                    _vm.api_base_url + '/storage/' + tem.image,
                    tem.id
                  )}}},[(_vm.selected_template == tem.id)?_c('i',{staticClass:"fa fa-check"}):_vm._e()])])})],2),_c('section',{staticClass:"hidden"},[_c('vue-croppie',{ref:"croppieRef",attrs:{"enableOrientation":true,"enableResize":false,"boundary":{ width: 328, height: 305 },"viewport":{ width: 328, height: 305, type: 'square' }},on:{"update":function($event){return _vm.update('croppieRef', 'custom_background_image')}}})],1)],1),_c('TextAreaField',{staticClass:"w-full",attrs:{"id":"description","label":"Persönlichen Nachricht","rules":"max:120"},model:{value:(_vm.form.note),callback:function ($$v) {_vm.$set(_vm.form, "note", $$v)},expression:"form.note"}}),(!_vm.data.data_json.isSpecialVoucher)?_c('span',{staticClass:"font-semibold text-sm mb-1"},[_vm._v(" Preis verstecken ")]):_vm._e(),(!_vm.data.data_json.isSpecialVoucher)?_c('div',{staticClass:"mb-1 relative w-full"},[(_vm.form.id)?_c('span',{staticClass:"mr-3"},[_c('toggle-button',{attrs:{"color":{
                checked: '#000',
                unchecked: '#fff',
                disabled: '#CCCCCC',
              },"switch-color":{
                checked: '#fff',
                unchecked: '#000',
                disabled: '#CCCCCC',
              }},on:{"change":function($event){_vm.formIndex++}},model:{value:(_vm.form.price_hidden),callback:function ($$v) {_vm.$set(_vm.form, "price_hidden", $$v)},expression:"form.price_hidden"}})],1):_vm._e(),_c('label',{staticClass:"text-sm col-span-2"},[_vm._v("nein / ja")])]):_vm._e(),_c('button',{staticClass:"company-bg-color px-5 py-3 rounded-md text-sm text-white mt-10 w-full"},[_vm._v(" Speichern ")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }